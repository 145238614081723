// ** Auth Endpoints
export default {




     cloudlinkbaseURL: 'https://portalapi.onobal.com/api/v1/',
   //cloudlinkbaseURL: 'http://localhost:5101/api/v1/',



     spinallinkbaseURL: 'https://identity.onobal.com/api/v1/',
  // spinallinkbaseURL: 'http://localhost:5000/api/v1/',






  loginEndpoint: 'User/authenticate',
  twofactorValidate: 'User/2fA_Validate',
  refreshEndpoint: 'User/refresh-token',

  registerEndpoint: '/jwt/register',
  logoutEndpoint: '/jwt/logout',

  forgotPasswordEndpoint: 'User/forgotPassword',
  setPasswordEndpoint: 'User/setPassword',

  setTempPassword: 'User/setPassword',





  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',


  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m'


}
